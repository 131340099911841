<template>
  <div class="row mt-3">
    <div class="col-md-12">
      <b-table striped hover
               :items="loans" id="table"
               :per-page="perPage"
               :fields="fields"
               selectable
               :current-page="currentPage"
               class="size-table">
        <template #cell(index)="data">
          <div class="form-checksss cks">
            <label >
              {{data.index + 1 }}
            </label>
          </div>
        </template>

        <template #cell(date)="data">
          {{ new Date(data.item.date).toDateString() }}
        </template>
        <template #cell(debit)="data">
          {{ formatters(data.item.debit) }}
        </template>
        <template #cell(credit)="data">
          {{ formatters(data.item.credit) }}
        </template>
        <template #cell(balance)="data">
          {{ formatters(data.item.balance) }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PdfTables",
  data () {
    return {
      perPage: 1000000,
      currentPage: 1,
      fields: [
        {
          key: 'index', label: 'S/N'
        },
        {
          key: 'date',
          label: 'Transaction Date',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'debit',
          label: 'Debit ',
        },
        {
          key: 'credit',
          label: 'Credit',
        },
        {
          key: 'balance',
          label: 'Loan balance',
        },
      ]
    }
  },
  props: {
    loans: {
      type: [Array, Object],
      required: false,
    }
  },
  computed: {
    scheduler() {
      let vm = this
      if (vm.loans && vm.loans.loan_schedule){
        let balance = vm.loans.requested_amt + vm.loans.loaninterest
            vm.loans.loan_schedule.map(val => {
              balance = balance - val.install_amount
              vm.items.push(
                {
                  date: val.schddate,
                  debit: 0,
                  description: `Amount paid is ${val.install_amount}`,
                  credit: val.install_amount,
                  balance: balance,
                },
          )
        })
      }
      return vm.items
    },

  },
}
</script>

<style scoped>

</style>