<template>
  <div class="container container-resize">
    <div class="row">
      <div class="col-md-12">
        <div class="image-logo mt-2 mb-2" style="background: #9B2275">
          <img src="/images/logo.svg" alt="Logo">
        </div>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>Plural Capital Limited:</p>
        <p>83, Adeniran Ogunsanya Street Surulere Lagos Nigeria:</p>
        <p>info@pluralcapitalltd.com:</p>
        <hr style="color: #1e2229">
      </div>
    </div>
    <div class="row dbs">
      <div class="col-md-7 dbs">
        <p>Account Officer: <span v-if="partner">{{ partner.refname4[1] }}</span></p>
        <p>Loan Account Id: <span v-if="partner">{{ partner.account_id }}</span></p>
        <p>Loan Account Type: <span v-if="partner">{{partner.document_client_id}}</span></p>
        <p>Currency: <span>NGN</span></p>
      </div>
      <div class="col-md-5">
        <p>Loan Account Number: <span v-if="partner">{{ partner.banknubanno }}</span></p>
        <p>Customer Bank: <span v-if="partner">{{ partner.bankname}}</span></p>
      </div>
    </div>
    <div class="row dbs">
      <div class="col-md-12">
        <h1>Customer: <span v-if="partner">{{partner.name}}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <pdf-tables :loans="applied" v-if="applied.length"></pdf-tables>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PdfTables from "./PdfTables";
export default {
  name: "ScheduleDesignsPdf",
  components: {PdfTables},
  computed: {
    ...mapGetters({
      applied: 'setup/pdf',
      partner: 'auth/partner',
    }),
  },
}
</script>

<style scoped>

</style>