<template>
  <div class="container container-resize">
    <hr>
    <div class="row dbs">
      <div class="col-md-12" >
        <p >Plural Capital Limited:</p>
        <p >83, Adeniran Ogunsanya Street Surulere Lagos Nigeria:</p>
        <p >info@pluralcapitalltd.com:</p>
        <hr >
      </div>
    </div>
    <div class="row dbs">
      <div class="col-md-7 ">
        <p>Account Officer: <span v-if="partner">{{ partner.refname4[1] }}</span></p>
        <p>Loan Account Id: <span v-if="partner">{{ partner.account_id }}</span></p>
        <p>Loan Account Type: <span v-if="partner">{{partner.document_client_id}}</span></p>
        <p>Currency: <span>NGN</span></p>
      </div>
      <div class="col-md-5">
        <p>Loan Account Number: <span v-if="partner">{{ partner.banknubanno }}</span></p>
        <p>Customer Bank: <span v-if="partner">{{ partner.bankname}}</span></p>
      </div>
    </div>
    <div class="row dbs">
      <div class="col-md-12">
        <h1>Customer: <span v-if="partner">{{partner.name}}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <pdf-tables :loans="applied" v-if="applied.length"></pdf-tables>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-4">
        <button type="button" class="btn button button-cta cta pt-2 float-right"
                @click.prevent="generateReport"
                v-if="applied.length">
          Download
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <vue-html2pdf :show-layout="false"
                      :float-layout="true"
                      :enable-download="true"
                      :preview-modal="false"
                      :paginate-elements-by-height="1400"
                      filename="Acount Statement"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="landscape"
                      pdf-content-width="1100px"
                      @hasGenerated="hasGenerated($event)"
                      ref="html2Pdf">
          <section slot="pdf-content">
            <schedule-designs-pdf :applied="applied" v-if="applied"></schedule-designs-pdf>
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import PdfTables from "./PdfTables";
import VueHtml2pdf from 'vue-html2pdf'
import ScheduleDesignsPdf from "./ScheduleDesignsPdf";
export default {
  name: "ScheduleDesigns",
  components: {ScheduleDesignsPdf, PdfTables, VueHtml2pdf},
  computed: {
    ...mapGetters({
      applied: 'setup/pdf',
      partner: 'auth/partner',
      schedule_loans: 'loan/schedule_loans'
    }),
  },
  methods: {
    ...mapMutations({
      Partner: 'auth/partner'
    }),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>